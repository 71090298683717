import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import handoutPDF from '../downloads/8-principles-handout-DEC2020.pdf'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

const HandoutDownload = ({ style }) => (
  <a href={handoutPDF} download>
    <StaticQuery
      query={graphql`
        query {
          placeholderImage: file(relativePath: { eq: "dei-pdf-graphic.png" }) {
            childImageSharp {
              fluid(maxWidth: 419, quality: 100, webpQuality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => <Img fluid={data.placeholderImage.childImageSharp.fluid} alt="download Business Case for DEI PDF" style={style} />}
    />
  </a>
);
export default HandoutDownload;
