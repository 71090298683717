import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';

import Seo from '../components/seo';
import Layout from '../components/layout';
import PullQuote from '../components/pullQuote';
import FlexContainer from '../components/flexContainer';
import HandoutDownload from '../components/handoutDownload';
import TemplateDownload from '../components/templateDownload';
import CoopPrinciplesUL from '../components/coopPrinciplesUL';
import GridTwoColumn from '../components/gridTwoColumn';
import GridFourColumn from '../components/gridFourColumn';
import GridContainer from '../components/gridContainer';
import MauriceSignature from '../components/mauriceSignature';
import MauriceImageSmall from '../components/mauriceImageSmall';
import FounderImage from '../components/founderImage';
import Iframe from '../components/iframe';

const HomePage = () => (
  <>
    <Helmet>
      <body className="home" />
    </Helmet>
    <Seo title="Diversity, Equity and Inclusion Talks" />
   
    <Layout isHomePage={true} >
   
      <section id="welcome">
      <div className="section-container">
     
        <h2><span>Welcome! We're glad you're here</span></h2>
         
        <p className="welcome-message">It’s important that you’re here.</p>
      
        <p>The philosophies behind diversity, equity and inclusion (DEI) are being raised anew in many communities, organizations and industries. As a participant in the cooperative movement, your leadership is needed in the DEI arena now more than ever.</p>
      
        <p>Join us in asking the International Co-operative Alliance (ICA) to adopt Diversity, Equity and Inclusion as the 8th Cooperative Principle. <a href="#resolution">Proclaim your commitment to the 8th Cooperative Principle</a></p>
      
      
        <h3>Dedication to DEI</h3>
      
        <p>As the Chief Executive Officer of Local Government Federal Credit  Union (LGFCU) and Civic Federal Credit Union (Civic), and the Vice Chairman of the AACUC, I am focused on ensuring the organizational culture and priorities of these organizations reflect the values of their members.</p>

        <p>Celebrating our differences is a powerful way to bring us together. That’s why we are continuing our longtime commitment to developing programs dedicated to DEI by sharing our latest initiative — an <a href="#eighth-principle">8th Cooperative Principle</a>.</p>
        
        <PullQuote>
          <p><strong> Cooperative Principle</strong></p>
          <p>Diversity, equity and inclusion — Cooperatives seek to incorporate diversity, perform equitably and ensure inclusion at all levels of their organizations to better serve members, show members they are valued and ensure members receive equitable service.</p>
        </PullQuote>
       
        <h3>Advancing cooperatives</h3>
       
        <p>The seven original <a href="#principles">Cooperative Principles</a> are a set of philosophical tenets illustrating cooperatives’ core values, economic model and governance practices. The application of these principles into everyday operations distinguishes cooperatives from other organizations. Absent from these principles, however, is a specific focus on DEI.</p>

        <p>Through the adoption of a new <a href="#eighth-principle">8th Cooperative Principle</a> — Diversity, Equity and Inclusion — we seek to demonstrate that DEI initiatives are not just the right thing to do morally and socially, but also make good business sense.</p>
     
        <h3>DEI for business</h3>
       
        <p>With a <a href="#business-case">Business Case for Diversity, Equity and Inclusion</a>, we are showing the world how successful our practice of ensuring and encouraging diversity in a business can be. Diversity can be good for the bottom line of any business, and we want you to join us in the success.</p>

        <p> I encourage you to continue reading below to learn more about DEI and pluralism. <a href="#eighth-principle">Watch the videos and read the white papers</a> to learn why they're good for business.</p>
       
        <h3>Commit to DEI</h3>
       
        <p>The time has come for us to give our DEI work the spotlight it deserves: to make DEI the main event. And we need your help doing so.</p>

        <p><a href="#resolution">Join us in the commitment to DEI</a> and make your voice heard to the ICA. Be clear about what your organization stands for. Join us and the <a href="#support">numerous others</a> leading their members and communities in this important crusade.</p>
        
        <p>We made it clear to the world that this is not a passing fad for us. Let's be unambiguous about what we stand for. I hope you’ll join us in this <a href="#resolution">important call to action</a>.</p>
       
        <FlexContainer className="maurice-sig-container">
          <div style={{ paddingTop: '1rem' }}>
            <strong>Cooperatively Yours,</strong><br />
            <MauriceSignature style={{ margin: '1rem 0 0 1rem' }}/>
          </div>       
       
          <MauriceImageSmall />
        </FlexContainer>
     
      </div>
      </section>
     
      <section id="principles">
      <div className="section-container">
     
        <h2><span>7 Cooperative Principles</span></h2>
       
        <span style={{ display: 'block' }}><em>Established 1844</em></span>       

        <Iframe source="https://player.vimeo.com/video/466285507" widthValue="600px" heightValue="400px"/>
     
        <CoopPrinciplesUL>
          <li><span>Voluntary & Open Membership</span><br />
            Cooperatives are voluntary organizations, open to all people able to use their services and willing to accept the responsibilities of membership, without gender, social, racial, political or religious discrimination.</li>
          <li><span>Democratic Member Control</span><br />
            Cooperatives are democratic organizations controlled by their members, who actively participate in setting their policies and making decisions.</li> 
          <li><span>Member Economic Participation</span><br />
            Members contribute equitably to, and democratically control, the capital of their cooperative.</li> 
          <li><span>Autonomy & Independence</span><br />
            Cooperatives are autonomous, self-help organizations controlled by their members.</li>
          <li className='second-column'><span>Education, Training & Information</span><br />
            Cooperatives provide education and training for their members, elected representatives, managers, and employees so they can contribute effectively to the development of their cooperatives.</li> 
          <li className='second-column'><span>Cooperation Among Cooperatives</span><br />
            Cooperatives serve their members most effectively and strengthen the cooperative movement by working together through local, national, regional and international structures.</li>
          <li className='second-column'><span>Concern for Community</span><br />
            Cooperatives work for the sustainable development of their communities through policies approved by their members.</li>
        </CoopPrinciplesUL>
     
      </div>
      </section>
     
      <section id="eighth-principle">
      <div className="section-container">
     
        <h2><span>8th Cooperative Principle</span></h2>
       
        <p style={{ marginBottom: '0' }}>Time for A New Cooperative Principle: Diversity, Equity & Inclusion</p>

        <Iframe source="https://player.vimeo.com/video/466261212" widthValue="600px" heightValue="400px" />
      

        <p>The Cooperative Principles were originally written in 1844, when the world looked different. By establishing an 8th Cooperative Principle, we are saying that diversity, equity and inclusion is an ideal that should be pursued.</p>

        <p>Cooperatives believe we are stronger when a proactive effort is put forth to engage everyone in governance, management and representation. The call to action is to simply engage in a conversation on how to make our public and private systems work for everyone. This is a movement whose time has come for action.</p>

      </div>
      </section>
     
      <section id="business-case">
      <div className="section-container">
       
        <GridTwoColumn>
          <div>  
            <h2><span>A Business Case for DEI</span></h2>
            <p>By ensuring a diverse staff that possess the skills and experience needed to face any scenario they’re presented with, organizations will be better positioned for success. Diversity expands the pool of capable candidates with valuable talents. Inclusion opens the door for advancements within the organization and the movement as a whole. Working together, diversity and inclusion create a powerful force for credit unions’ success.</p>
          </div>

          <div className="download-thumbnail">
            <HandoutDownload style={{ maxWidth: '419px' }} />
          </div>
        </GridTwoColumn>
     
      </div>
      </section>
     
      <section id="why">
      <div className="section-container">
       
        <h2><span>Why DEI Matters</span></h2>
     
        <Iframe source="https://player.vimeo.com/video/468551460" widthValue="600px" heightValue="400px" />

        <div style={{ textAlign: 'center' }} >
          <h3>Cooperatives addressing DEI</h3>
          <p><a href="https://www.cudeicollective.org/" target="_blank" rel="noopener" style={{
            fontSize: '1.2em',
            fontWeight: 'bold'
          }}>CU DEI Collective</a></p>
          
          <p><a href="https://news.cuna.org/articles/116621-cuna-board-resolution-establishes-dei-as-cu-cooperative-principle" target="_blank" rel="noopener" style={{
            fontSize: '1.2em',
            fontWeight: 'bold'
          }}>CUNA resolves to establish DEI as CU Cooperative Principle</a></p>

          <p><a href="https://www.carolinasleague.org/page/cooperativeprinciples" target="_blank" rel="noopener" style={{
            fontSize: '1.2em',
            fontWeight: 'bold'
          }}>Carolinas Credit Union League endorse 8th Cooperative Principle</a></p>
        </div>
     
      </div>
      </section>

      <section id="resolution">
      <div className="section-container">
       
        <GridTwoColumn style={{ marginBottom: '3rem' }}>
          <div>
            <h2><span>Resolution</span></h2>
            <p>A prime opportunity has come for the cooperative movement to further distinguish itself from other industries. To this end, we invite everyone to pledge their commitment to embracing the 8th Cooperative Principle of Diversity, Equity and Inclusion.</p>
          </div>

          <div className="download-thumbnail">
            <TemplateDownload style={{ maxWidth: '419px' }} />
          </div>
        </GridTwoColumn>

      {/*
        <Iframe source="https://lgfcu.formstack.com/forms/deitalks_supporter" title="DEI logo upload" widthValue="600px" heightValue="500px"/>
             
          <p style={{ textAlign: 'center' }}>Your email will not be listed publicly or on the Wall of Support, and will not be sold, rented or leased to a third party.</p>
      */}

      </div>
      </section>
     
      <section id="support">
      <div className="section-container">
       
        <h2><span>A Show of Support</span></h2>
       
        <p>Cooperatives have shown the world their commitment to diversity, equity and inclusion by embracing the <a href="#eighth-principle">8th Cooperative Principle</a>. Join us in celebrating these DEI leaders of the cooperative movement.</p>
       
        <h3 style={{textAlign: 'center', marginTop: '50px'}}>Founding Organizations of the 8th Cooperative Principles</h3>

        <FlexContainer justifyContent="space-around" className="founder-image-container" >
          <FounderImage imageName="aacuc" alt="AACUC" />
          <FounderImage imageName="civic" alt="Civic FCU" />
          <FounderImage imageName="lgfcu" alt="LGFCU" />
        </FlexContainer>
       
        <h3 style={{textAlign: 'center'}} className="supporting-heading">Supporting Organizations</h3>

        <GridContainer className="supporting-image-container" >
          
          <StaticImage src="../images/supporting/coop-logo.png" width={238} height={55} alt="Cooperative Council of NC" />

          <StaticImage src="../images/supporting/nova.png" width={210} height={82} alt="Nova Credit Union" />

          <StaticImage src="../images/supporting/farmers-insurance.png" width={280} height={40} alt="Farmers Insurance Federal Credit Union" />

          <StaticImage src="../images/supporting/cues-logo.png" width={200} height={96} alt="CUES" />

          <StaticImage src="../images/supporting/worldwide-foundation.jpg" width={300} height={61} alt="Worldwide Foundation for Credit Unions" />

          <StaticImage src="../images/supporting/coastal-logo-green.png" width={225} height={90} alt="Coastal Credit Union" />

          <StaticImage src="../images/supporting/checkalt.png" width={260} height={80} alt="CheckAlt" />

          <StaticImage src="../images/supporting/dover.jpg" width={140} height={129} alt="Dover Federal Credit Union" />

          <StaticImage src="../images/supporting/dcuc-logo-vertical.jpg" width={121} height={130} alt="Defense Credit Union Council" />

          <StaticImage src="../images/supporting/ccuf.jpg" width={153} height={130} alt="Carolinas Credit Union Foundation" />

          <StaticImage src="../images/supporting/pscu.jpg" width={144} height={67} alt="PSCU" />

          <StaticImage src="../images/supporting/you-cu-partner.png" width={259} height={84} alt="Your Credit Union Partner" />

          <StaticImage src="../images/supporting/nlcup.jpg" width={233} height={130} alt="National Association of Latino Credit Unions & Professionals" />

          <StaticImage src="../images/supporting/illinois-cu-league.png" width={264} height={80}alt="Illinois Credit Union League" />

          <StaticImage src="../images/supporting/bfcu.jpg" width={272} height={136} alt="Border Federal Credit Union" />

          <StaticImage src="../images/supporting/coopr8.png" width={300} height={100} alt="CoopR8 Consulting" />

          <StaticImage src="../images/supporting/ccul-logo.jpg" width={210} height={101} alt="Carolinas Credit Union League" />

          <StaticImage src="../images/supporting/long-beach.png" width={189} height={184} alt="Long Beach Grocery Co-op" />

          <StaticImage src="../images/supporting/elementfcu.png" width={246} height={138} alt="Element Federal Credit Union" />

          <StaticImage src="../images/supporting/spero-financial.png" width={156} height={134} alt="Spero Financial" />

          <StaticImage src="../images/supporting/numark-cu.png" width={180} height={180} alt="NuMark Credit Union" />

          <StaticImage src="../images/supporting/schoolfirstfcu.png" width={281} height={40} alt="Schools First Federal Credit Union" />

          <StaticImage src="../images/supporting/heartland-cua.png" width={230} height={66} alt="Heartland Credit Union Association" />

          <StaticImage src="../images/supporting/coop-cua.png" width={280} height={86} alt="Cooperative Credit Union Association" />

          <StaticImage src="../images/supporting/heartland-cu-charitable.png" width={288} height={107} alt="Heartland Credit Union Charitable Foundation" />

          <StaticImage src="../images/supporting/michael-walters-advertising.png" width={268} height={47} alt="Michael Walters Advertising" />

          <StaticImage src="../images/supporting/piedmont-advantage.png" width={246} height={93} alt="Piedmont Advantage Credit Union" />

          <StaticImage src="../images/supporting/inclusiv.png" width={245} height={69} alt="Inclusive" />

          <StaticImage src="../images/supporting/unituscu.png" width={254} height={92} alt="Unitus Community Credit Union" />

          <StaticImage src="../images/supporting/phelpefcu.png" width={240} height={58} alt="Phelpe Federal Credit Union" />
        </GridContainer>

        <h3 style={{textAlign: 'center'}} className="supporting-heading">Individual Supporter</h3>
        <GridFourColumn>
        <p style={{textAlign: 'center'}}>Graham Mickleborough</p>
        <p style={{textAlign: 'center'}}>Meena Neva</p>
        <p style={{textAlign: 'center'}}>Ciara LaVelle</p>
        <p style={{textAlign: 'center'}}>Andrew Escobar</p>
        <p style={{textAlign: 'center'}}>Biddieya Smith</p>
        <p style={{textAlign: 'center'}}>Daniel West</p>
        <p style={{textAlign: 'center'}}>Andreas Bastias</p>
        <p style={{textAlign: 'center'}}>Majal Logan</p>
        </GridFourColumn>
      </div>
      </section>
     
      <section id="learn-more">
        <div className="section-container">
          <h3>Have questions about the 8th Cooperative Principle? Email us at <a href="mailto:DEItalks@lgfcu.org">DEItalks@lgfcu.org</a>.</h3>
        </div>
      </section>
   
    </Layout> 
  </>
);

export default HomePage;
