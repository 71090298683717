import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const FounderImage = ({ imageName }) => {
  const data = useStaticQuery(graphql`
    fragment fixed203 on File {
      childImageSharp {
        fixed(width: 203, quality: 100, webpQuality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    fragment fixed185 on File {
      childImageSharp {
        fixed(width: 185, quality: 100, webpQuality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    fragment fixed171 on File {
      childImageSharp {
        fixed(width: 171, quality: 100, webpQuality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    
    query {
      lgfcu: file(relativePath: { eq: "founders/dei-lgfcu-support-logo@2x.png" }) {
        ...fixed203
      }
    
      civic: file(relativePath: { eq: "founders/dei-civic-support-logo@2x.png" }) {
        ...fixed185
      }
    
      aacuc: file(relativePath: { eq: "founders/dei-aacuc-support-logo@2x.png" }) {
        ...fixed171
      }
    }
  `)

  return (
    <Img fixed={data[imageName].childImageSharp.fixed} />
  )
};
export default FounderImage;
