import React from 'react';
import IframeResizer from 'iframe-resizer-react';
import styled from 'styled-components';

const IframeContainer = styled.div`
  height: 700px;
  margin-top: 50px;
  margin-bottom: 50px;

  @media (min-width: 800px) {
    height: 650px;
  }
`

const Iframe = ({source, widthValue, heightValue}) => (
  <IframeContainer className="iframe-container">
    <IframeResizer
      loading="lazy"
      src={source}
      frameBorder="0" 
      allow="autoplay; fullscreen" 
      scrolling="no" 
      // style={{ width: '1px', minWidth: '100%', minHeight: `${heightValue}`}}
      style={{ width: '1px', minWidth: '100%', minHeight: '100%', display: 'block' }}
    />
  </IframeContainer>
);

export default Iframe;