import styled from 'styled-components';

import numberOne from '../images/list-numbers/dei-n1.svg';
import numberTwo from '../images/list-numbers/dei-n2.svg';
import numberThree from '../images/list-numbers/dei-n3.svg';
import numberFour from '../images/list-numbers/dei-n4.svg';
import numberFive from '../images/list-numbers/dei-n5.svg';
import numberSix from '../images/list-numbers/dei-n6.svg';
import numberSeven from '../images/list-numbers/dei-n7.svg';

const CoopPrinciplesUL = styled.ul`
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, 1fr);
  grid-auto-rows: minmax(min-content, max-content);
  grid-auto-flow: column;
  grid-column-gap: 50px;
  grid-row-gap: 30px;
  margin-bottom: 0;

  & li {
    margin-bottom: 0;
    margin-left: 75px;
    position: relative;

    &::before {
      content: '';
      width: 54px;
      height: 86px;
      position: absolute;
      left: -75px;
      background-position: 0 50%;
      background-repeat: no-repeat;
    }

    &:first-of-type::before { background-image: url(${numberOne}); }
    &:nth-of-type(2)::before { background-image: url(${numberTwo}); }
    &:nth-of-type(3)::before { background-image: url(${numberThree}); }
    &:nth-of-type(4)::before { background-image: url(${numberFour}); }
    &:nth-of-type(5)::before { background-image: url(${numberFive}); }
    &:nth-of-type(6)::before { background-image: url(${numberSix}); }
    &:nth-of-type(7)::before { background-image: url(${numberSeven}); }

    & span {
      display: inline-block;
      margin-bottom: .5rem;
      font-size: 20px;
      font-weight: bold;
      color: #d51c32;
      text-transform: uppercase;
    }
  }

  & li.second-column {
    grid-column-start: 2;
  }

  @media (max-width: 800px) {
    display: block;
    margin-left: 0;

    & li { margin-bottom: 50px; }
  }
`;

export default CoopPrinciplesUL;