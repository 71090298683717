import React from 'react';
import styled from 'styled-components';

const GridDiv = styled.div`
  display: grid;
  align-items: ${props => props.alignItems || "center"};
  justify-items: ${props => props.justifyItems  || "center"};
`;

const GridContainer = ({ justifyItems, className, children }) => (
  <GridDiv justifyItems={justifyItems} className={(className || '') + " grid-container"}>
    {children}
  </GridDiv>
);

export default GridContainer;