import styled from 'styled-components';

const GridFourColumn = styled.div`
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-gap: 20px;

  @media (max-width: 1000px) {
    display: block;
    /* display: flex;
    flex-direction: column;
    align-items: center; */

    & > div:last-of-type {
      text-align: center;
      
      & div.gatsby-image-wrapper {
        margin: 10px auto;
      }
    }
  }
`;

export default GridFourColumn;