import styled from 'styled-components';

const GridTwoColumn = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 50px;

  @media (max-width: 1000px) {
    display: block;
    /* display: flex;
    flex-direction: column;
    align-items: center; */

    & > div:last-of-type {
      text-align: center;
      
      & div.gatsby-image-wrapper {
        margin: 30px auto;
      }
    }
  }
`;

export default GridTwoColumn;