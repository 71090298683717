import React from 'react';
import styled from 'styled-components';
import backgroundImage from '../images/pull-quote-lines.svg';

const PullQuoteContainer = styled.div`
  color: #fff;
  background: #0e78b2; 
  background: #006ead;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-bottom: 2rem;

  & .section-container {
    position: relative;

    & * {
      position: relative;
      z-index: 10;
    }

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      /* background: #0e78b2; */
      background: #006ead;
      clip-path: polygon(0 0,83.6% 0,90% 101%,0 101%);
      position: absolute;
      top: 0;
      z-index: 1;
    }

    @media (min-width: 1100px) {
      /* background-image: url(${backgroundImage});*/
      background-position-x: 95%;
      background-size: auto 100%;
      background-repeat: no-repeat;
    }
  }

  & p { 
    font-size: 24px;
    line-height: 1.4;
    padding: .5em 2rem .5em 2.5rem;
    margin-bottom: 0; 

    @media (min-width: 800px) {
      width: 75%;
    }

    @media (min-width: 1100px) {
      padding: .5em 0;
    }
  }

  @media (min-width: 800px) {
    /* background: linear-gradient(to right, #0e78b2 0, #0e78b2 50%, #fff 50%, #fff 100%); */
    background: linear-gradient(to right, #006ead 0, #006ead 50%, #fff 50%, #fff 100%);
  }
`;

const PullQuote = ({ children }) => (
  <PullQuoteContainer>
    <div className="section-container" style={{ padding: '1rem 0' }}>
      {children}
    </div>
  </PullQuoteContainer>
);

export default PullQuote;